<template>
  <div id="pageCadastroCodigoBarras">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg8 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title class="subheading">Gerar código de barras</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <codigo-barras-form></codigo-barras-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import CodigoBarrasForm from "@/components/codigoBarras/formCodigoBarras.vue"
export default {
  components: {
    CodigoBarrasForm
  },
  data: () => ({})
}
</script>
