<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Referência"
            name="referencia"
            placeholder="Referência"
            v-model="codigoBarras.ReferenciaInformada"
            :rules="referenciaRules"
            :counter="4"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Valor"
            name="valor"
            placeholder="Valor"
            v-model="codigoBarras.ValorInformado"
            :rules="valorRules"
            :counter="5"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            type="number"
            class="mr-2"
            label="Quantidade"
            name="quantidade"
            placeholder="Quantidade"
            v-model="codigoBarras.Quantidade"
            :rules="quantidadeRules"
            required
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Tamanho"
            name="tamanho"
            placeholder="Tamanho"
            v-model="codigoBarras.Tamanho"
            :rules="tamanhoRules"
            :counter="5"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Descricao"
            name="descricao"
            placeholder="Descricao"
            v-model="codigoBarras.Descricao"
            :rules="descricaoRules"
            :counter="100"
            required
          ></v-text-field>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="clear">Limpar</v-btn>
      <v-btn outline @click="voltar">Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoCodigoBarras } from "../../servicos/servicoCodigoBarras"
import CodigoBarrasInformacoes from "../../domain/codigoBarras/CodigoBarrasInformacoes.js"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoCodigoBarras = new ServicoCodigoBarras()
export default {
  components: {
    ProgressCircular
  },
  data() {
    return {
      codigoBarras: new CodigoBarrasInformacoes(),
      id: null,
      referenciaRules: [
        v => !!v || "Referência é obrigatória",
        v => v.length <= 4 || "Referência não deve possuir mais que 4 caracteres"
      ],
      quantidadeRules: [v => !!v || "Quantidade é obrigatória"],
      valorRules: [v => v.length <= 5 || "Valor não deve possuir mais que 5 caracteres"],
      tamanhoRules: [v => v.length <= 5 || "Tamanho não deve possuir mais que 5 caracteres"],
      descricaoRules: [v => v.length <= 100 || "Tamanho não deve possuir mais que 100 caracteres"],
      valid: true
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.abrirProgressCircular()
        this.codigoBarras.Quantidade = parseInt(this.codigoBarras.Quantidade)
        servicoCodigoBarras.salvarCodigoBarras(this.codigoBarras).then(
          res => {
            if (res.status === 200) {
              this.fecharProgressCircular()
              this.$router.push({ name: "CodigoBarras" })
            } else {
              this.fecharProgressCircular()
            }
          },
          err => {
            this.fecharProgressCircular()
      // eslint-disable-next-line
            console.log(err)
          }
        )
      }
    },
    clear() {
      this.codigoBarras = new CodigoBarrasInformacoes()
      this.$refs.form.resetValidation()
    },
    closeDialog() {
      this.$parent.isActive = false
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "CodigoBarras" })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
