export default class CodigoBarrasInformacoes {
  constructor(id = 0, referenciaInformada = "", valorInformado = "", tamanho = "", quantidade = "", descricao = "") {
    this.Id = id
    this.ReferenciaInformada = referenciaInformada
    this.ValorInformado = valorInformado
    this.Tamanho = tamanho
    this.Quantidade = quantidade
    this.Descricao = descricao
  }
}
